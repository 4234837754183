import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
// import { HashRouter as Router, Route, Redirect } from "react-router-dom";

import Home from './pages/home/index';
import Product from './pages/product/index';
import About from './pages/about/index';
import Company from './pages/company/index';

function App() {
    return (
        <Router>
            <Route exact path="/" component={() => <Redirect to="/zh-cn/index.html" />} />
            <Route path="/:lang/index.html" component={Home} />
            <Route path="/:lang/product.html" component={Product} />
            <Route path="/:lang/about.html" component={About} />
            <Route path="/:lang/company.html" component={Company} />
        </Router>
    );
}

export default App;
