import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import './index.scss';

interface Props {
    lang: Lang
}

interface State {
    slogan: LangContent
    featureList: Array<{
        name: LangContent,
        id: string,
        feature: Array<{
            content: LangContent
        }>
    }>
}

class Product extends React.Component<RouteComponentProps<any> & Props, State> {
    constructor(props: RouteComponentProps & Props) {
        super(props);
    }

    state = {
        slogan: {
            'zh-cn': {
                t1: '腾讯鲲鹏',
                t2: '券商系统介绍  定制服务  一站式服务'
            },
            'zh-hk': {
                t1: '騰訊鯤鵬',
                t2: '券商系統介紹 定制服務 一站式服務'
            }
        },
        featureList: [{
            name: {
                'zh-cn': '交易系统',
                'zh-hk': '交易系統'
            },
            id: 'trade',
            feature: [{
                content: {
                    'zh-cn': '前台证券交易系统',
                    'zh-hk': '前台證券交易系統'
                },
            }, {
                content: {
                    'zh-cn': '支持快速下单',
                    'zh-hk': '支持快速下單'
                }
            }, {
                content: {
                    'zh-cn': '支持行情展示',
                    'zh-hk': '支持行情展示'
                }
            }]
        }, {
            name: {
                'zh-cn': '清算系统',
                'zh-hk': '清算系統'
            },
            id: 'clearing',
            feature: [{
                content: {
                    'zh-cn': '清算管理',
                    'zh-hk': '清算管理'
                },
            }, {
                content: {
                    'zh-cn': '合规报表',
                    'zh-hk': '合規報表'
                }
            }, {
                content: {
                    'zh-cn': '会计对接',
                    'zh-hk': '會計對接'
                }
            }]
        }, {
            name: {
                'zh-cn': '反洗钱中心',
                'zh-hk': '反洗錢中心'
            },
            id: 'risk',
            feature: [{
                content: {
                    'zh-cn': '反洗钱中心',
                    'zh-hk': '反洗錢中心'
                },
            }, {
                content: {
                    'zh-cn': '预警中心',
                    'zh-hk': '預警中心'
                }
            }, {
                content: {
                    'zh-cn': '阈值规则管理',
                    'zh-hk': '閾值規則管理'
                }
            }, {
                content: {
                    'zh-cn': '风控报表',
                    'zh-hk': '風控報表'
                }
            }]
        }, {
            name: {
                'zh-cn': '账户管理系统',
                'zh-hk': '賬戶管理系統'
            },
            id: 'account',
            feature: [{
                content: {
                    'zh-cn': '开户管理',
                    'zh-hk': '開戶管理'
                },
            }, {
                content: {
                    'zh-cn': '资金管理',
                    'zh-hk': '資金管理'
                }
            }, {
                content: {
                    'zh-cn': '股票管理',
                    'zh-hk': '股票管理'
                }
            }]
        }, {
            name: {
                'zh-cn': '移动端APP应用程序',
                'zh-hk': '移動端APP應用程序'
            },
            id: 'mobile',
            feature: [{
                content: {
                    'zh-cn': 'iOS',
                    'zh-hk': 'iOS'
                },
            }, {
                content: {
                    'zh-cn': 'Android',
                    'zh-hk': 'Android'
                }
            }, {
                content: {
                    'zh-cn': 'H5等客户端支持',
                    'zh-hk': 'H5等客戶端支持'
                }
            }]
        }, {
            name: {
                'zh-cn': '客服系统',
                'zh-hk': '客服系統'
            },
            id: 'customer',
            feature: [{
                content: {
                    'zh-cn': '客户资料管理',
                    'zh-hk': '客戶資料管理'
                },
            }, {
                content: {
                    'zh-cn': '客服服务记录',
                    'zh-hk': '客服服務記錄'
                }
            }]
        }, {
            name: {
                'zh-cn': '行情',
                'zh-hk': '行情'
            },
            id: 'market',
            feature: [{
                content: {
                    'zh-cn': 'BMP行情服务',
                    'zh-hk': 'BMP行情服務'
                },
            }, {
                content: {
                    'zh-cn': '热门行业涨跌榜',
                    'zh-hk': '熱門行業漲跌榜'
                }
            }, {
                content: {
                    'zh-cn': '资金流向分析',
                    'zh-hk': '資金流向分析'
                }
            }]
        }, {
            name: {
                'zh-cn': '智能增值服务',
                'zh-hk': '智能增值服務'
            },
            id: 'appreciation',
            feature: [{
                content: {
                    'zh-cn': '智能选股',
                    'zh-hk': '智能選股'
                },
            }, {
                content: {
                    'zh-cn': '社区服务',
                    'zh-hk': '社區服務'
                }
            }, {
                content: {
                    'zh-cn': '智能客服',
                    'zh-hk': '智能客服'
                }
            }]
        }]
    };

    render() {
        const defaultLang: Lang = 'zh-cn';
        const lang = this.props.match.params.lang as Lang || defaultLang;
        const slogan = this.state.slogan[lang];
        const featureList = this.state.featureList;

        return (
            <div className="product-wrapper">
                <Header {...this.props} />
                <main className="main">
                    <div className="banner">
                        <h2 className="font58">{slogan.t1}</h2>
                        <h2 className="font48">{slogan.t2}</h2>
                    </div>
                    <div className="feature-container">
                        {featureList.map(feature => (
                            <div className="feature-item" key={feature.id}>
                                <img src={require(`../../images/features/${feature.id}.png`)} alt={feature.id} className="feature-img"/>
                                <div className="feature-content">
                                    {feature.feature.map((item, index) => (
                                        <p key={index} className="feature-content-item">
                                            <span className="dot"></span>
                                            <span>{item.content[lang]}</span>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </main>
                <Footer {...this.props} />
            </div>
        );
    }
}

export default Product;
