import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import './index.scss';

interface Props {
    lang: Lang
}


class Company extends React.Component<RouteComponentProps<any> & Props> {
    constructor(props: RouteComponentProps & Props) {
        super(props);
    }

    state = {
        info: {
            'zh-cn': {
                home: '首页',
                companyInfo: '公司信息',
                content: [
                    '腾讯，1998年11月诞生于中国深圳，是一家以互联网为基础的科技与文化公司。我们的使命是“通过互联网服务提升人类生活品质”。腾讯秉承着 “一切以用户价值为依归”的经营理念，为亿万网民提供优质的互联网综合服务。',
                    '腾讯的战略目标是“连接一切”，我们长期致力于社交平台与数字内容两大核心业务：一方面通过微信与QQ等社交平台，实现人与人、服务及设备的智慧连接；另一方面为数以亿计的用户提供优质的新闻、视频、游戏、音乐、文学、动漫、影业等数字内容产品及相关服务。我们还积极推动金融科技的发展，通过普及移动支付等技术能力，为智慧交通、智慧零售、智慧城市等领域提供有力支持。',
                    '腾讯希望成为各行各业的数字化助手，助力数字中国建设。在工业、医疗、零售、教育等各个领域，腾讯为传统行业的数字化转型升级提供“数字接口”和“数字工具箱”。我们秉持数字工匠精神，希望用数字创新提升每个人的生活品质。随着“互联网+”战略实施和数字经济的发展，我们通过战略合作与开放平台，与合作伙伴共建数字生态共同体，推进云计算、大数据、人工智能等前沿科技与各行各业的融合发展及创新共赢。多年来，腾讯的开放生态带动社会创业就业人次达数千万，相关创业企业估值已达数千亿元。',
                    '腾讯的愿景是成为“最受尊敬的互联网企业”。我们始终坚守“科技向善”的初心，运用科技手段助力公益事业发展，并将社会责任融入每一个产品。2007年，腾讯倡导并发起了中国互联网第一家在民政部注册的全国性非公募基金会——腾讯公益慈善基金会。腾讯公益致力于成为“人人可公益的创连者”，以互联网核心能力推动公益行业的长远发展为己任。腾讯公益联合多方发起了中国首个互联网公益日——99公益日，帮助公益组织和广大爱心网友、企业之间形成良好的公益生态，让透明化的“指尖公益”融入亿万网民的生活。'
                ]
            },
            'zh-hk': {
                home: '首頁',
                companyInfo: '公司信息',
                content: [
                    '騰訊，1998年11月誕生於中國深圳，是一家以互聯網為基礎的科技與文化公司。我們的使命是“通過互聯網服務提升人類生活品質”。騰訊秉承著 “一切以用戶價值為依歸”的經營理念，為億萬網民提供優質的互聯網綜合服務。',
                    '騰訊的戰略目標是“連接一切”，我們長期致力於社交平台與數字內容兩大核心業務：一方面通過微信與QQ等社交平台，實現人與人、服務及設備的智慧連接；另一方面為數以億計的用戶提供優質的新聞、視頻、遊戲、音樂、文學、動漫、影業等數字內容產品及相關服務。我們還積極推動金融科技的發展，通過普及移動支付等技術能力，為智慧交通、智慧零售、智慧城市等領域提供有力支持。',
                    '騰訊希望成為各行各業的數字化助手，助力數字中國建設。在工業、醫療、零售、教育等各個領域，騰訊為傳統行業的數字化轉型升級提供“數字接口”和“數字工具箱”。我們秉持數字工匠精神，希望用數字創新提升每個人的生活品質。隨著“互聯網+”戰略實施和數字經濟的發展，我們通過戰略合作與開放平台，與合作夥伴共建數字生態共同體，推進云計算、大數據、人工智能等前沿科技與各行各業的融合發展及創新共贏。多年來，騰訊的開放生態帶動社會創業就業人次達數千萬，相關創業企業估值已達數千億元。',
                    '騰訊的願景是成為“最受尊敬的互聯網企業”。我們始終堅守“科技向善”的初心，運用科技手段助力公益事業發展，並將社會責任融入每一個產品。 2007年，騰訊倡導並發起了中國互聯網第一家在民政部註冊的全國性非公募基金會——騰訊公益慈善基金會。騰訊公益致力於成為“人人可公益的創連者”，以互聯網核心能力推動公益行業的長遠發展為己任。騰訊公益聯合多方發起了中國首個互聯網公益日——99公益日，幫助公益組織和廣大愛心網友、企業之間形成良好的公益生態，讓透明化的“指尖公益”融入億萬網民的生活。'
                ]
            }
        }
    };

    render() {
        const defaultLang: Lang = 'zh-cn';
        const lang = this.props.match.params.lang as Lang || defaultLang;
        const info = this.state.info[lang];

        return (
            <div className="company-wrapper">
                <Header {...this.props} />
                <main className="main">
                    <nav className="path-area">
                        <Link to="/">{info.home}</Link>
                        <span> {'>'} {info.companyInfo}</span>
                    </nav>
                    <article className="article">
                        <h2>{info.companyInfo}</h2>
                        {info.content.map((p, index) => <p key={index}>{p}</p>)}
                    </article>
                </main>
                <Footer {...this.props} />
            </div>
        );
    }
}

export default Company;
