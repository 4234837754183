import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import './index.scss';

interface Props extends RouteComponentProps<{ lang: string }> {
    lang: string
}

class Footer extends React.Component<Props> {
    state = {
        info: {
            'zh-cn': {
                name: '腾讯科技（深圳）有限公司',
                address: '地址：广东省深圳南山区海天二路腾讯滨海大厦',
                email: '邮箱',
                tel: {
                    c1: '电话',
                    c2: '转'
                },
                copyright: '腾讯公司 版权所有'
            },
            'zh-hk': {
                name: '騰訊科技（深圳）有限公司',
                address: '地址：廣東省深圳南山區海天二路騰訊濱海大廈',
                email: '郵箱',
                tel: {
                    c1: '電話',
                    c2: '轉'
                },
                copyright: '騰訊公司 版權所有'
            },
        }
    }

    render() {
        const defaultLang: Lang = 'zh-cn';
        const lang = this.props.match.params.lang as Lang || defaultLang;
        const info = this.state.info[lang];

        return (
            <footer className="footer">
                <div className="info-container">
                    <h3 className="info-company">{info.name}</h3>
                    <address className="info-text">{info.address}</address>
                    <div className="info-others">
                        <p className="info-text">{info.email}：<a href="mailto:broker@tencent.com" className="info-text--inline">broker@tencent.com</a></p>
                        <p className="info-text">{info.tel.c1}：<a href="tel:0755-86013388" className="info-text--inline">0755-86013388 {info.tel.c2} 813236</a></p>
                        <p className="info-text">Copyright©1998-2023 Tencent. All rights reserved. {info.copyright}</p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
