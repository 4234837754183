import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import './index.scss';

interface Props {
    lang: Lang
}

interface State {
    badges: Array<object>
}

class About extends React.Component<RouteComponentProps<any> & Props, State> {
    constructor(props: RouteComponentProps & Props) {
        super(props);
    }

    state = {
        slogan: {
            'zh-cn': '连接你我 共生未来',
            'zh-hk': '連接你我 共生未來'
        },
        info: {
            'zh-cn': {
                title: '以互联网为基础的科技和文化公司',
                content: [
                    '腾讯，1998年11月诞生于中国深圳，是一家以互联网为基础的科技与文化公司。',
                    '我们的使命是“通过互联网服务提升人类生活品质”。',
                    '腾讯秉承着“一切以用户价值为依归”的经营理念，致力于为亿万网民提供优质的互联网综合服务。'
                ]
            },
            'zh-hk': {
                title: '以互聯網為基礎的科技和文化公司',
                content: [
                    '騰訊，1998年11月誕生於中國深圳，是一家以互聯網為基礎的科技與文化公司。',
                    '我們的使命是“通過互聯網服務提升人類生活品質”。',
                    '騰訊秉承著“一切以用戶價值為依歸”的經營理念，致力於為億萬網民提供優質的互聯網綜合服務。'
                ]
            }
        },
        badges: [{
            id: 'aptitude',
            content: {
                'zh-cn': '香港证券交易所公布的合格BSS供应商',
                'zh-hk': '香港證券交易所公佈的合格BSS供應商'
            }
        }, {
            id: 'vision',
            content: {
                'zh-cn': '我们的愿景是成为最受尊敬的互联网企业',
                'zh-hk': '我們的願景是成為最受尊敬的互聯網企業'
            }
        }, {
            id: 'mission',
            content: {
                'zh-cn': '我们的使命是通过互联网服务提升人类的生活品质',
                'zh-hk': '我們的使命是通過互聯網服務提升人類的生活品質'
            }
        }]
    };

    render() {
        const defaultLang: Lang = 'zh-cn';
        const lang = this.props.match.params.lang as Lang || defaultLang;
        const badges = this.state.badges;
        const slogan = this.state.slogan[lang];
        const info = this.state.info[lang];

        return (
            <div className="about-wrapper">
                <Header {...this.props} backgroundImage={require('../../images/about-bg.png')}>
                    <div className="header-banner">
                        <h2 className="header-slogan">{slogan}</h2>
                    </div>
                </Header>
                <main className="main">
                    <div className="main-info">
                        <h3 className="company-slogan">{info.title}</h3>
                        {info.content.map((p, index) => <p className="company-detail" key={index}>{p}</p>)}
                        <Link className="company-more" to={`/${lang}/company.html`}>了解更多 {'>'}</Link>
                    </div>
                    <div className="badges-list">
                        {badges.map(badge => (
                            <div className="badge-item" key={badge.id}>
                                <img src={require(`../../images/${badge.id}.png`)} alt={badge.content[lang]} className="badge-img"/>
                                <p>{badge.content[lang]}</p>
                            </div>
                        ))}
                    </div>
                    <div className="main-public">
                        <img src={require('../../images/about-ad.png')} alt="public"/>
                    </div>
                </main>
                <Footer {...this.props} />
            </div>
        );
    }
}

export default About;
