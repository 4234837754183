import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import './index.scss';

interface Props {
    lang: Lang
}

interface State {
    intl: LangContent
};


class Home extends React.Component<RouteComponentProps<any> & Props, State> {
    constructor(props: RouteComponentProps & Props) {
        super(props);
    }

    state: State = {
        intl: {
            'zh-cn': {
                t1: '香港券商综合服务解决方案',
                t2: '科技驱动金融创新'
            },
            'zh-hk': {
                t1: '香港券商綜合服務解決方案',
                t2: '科技驅動金融創新'
            },
        }
    };

    render() {
        const defaultLang: Lang = 'zh-cn';
        const lang = this.props.match.params.lang as Lang || defaultLang;
        const content = this.state.intl[lang];

        return (
            <div className="home-wrapper">
                <Header {...this.props} />
                <main className="main">
                    <img className="main-logo" src={require("../../images/logo.png")} alt="logo"/>
                    <h2 className="font68">{content.t1}</h2>
                    <h2 className="font36">{content.t2}</h2>
                </main>
                <Footer {...this.props} />
            </div>
        );
    }
}

export default Home;
